<template>
  <div class="admin-container">
    <div class="header">
      <div><img @click="$router.push('/')" src="@/assets/wifi-block-logo.svg" /></div>
      <div class="links">
        <div>Orders</div>
        <div>
          <a href="/admin/templates">Templates</a>
        </div>
        <div>
          <a href="/admin/jobs" target="_blank">Jobs</a>
        </div>
        <div>
          <a
            href="https://docs.google.com/spreadsheets/d/1gDreYumKT6raXLYYETfrwlP5SZbgkb8l7eEXkYrWJtY/edit?ts=5f335061#gid=0"
            target="_blank"
            >Inventory & Costs</a
          >
        </div>
      </div>
    </div>

    <div class="container-body">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdminLayout',
  async mounted() {
    try {
      this.$store.dispatch('admin/setInitialLoad');
    } catch (ex) {
      this.$router.push('/login');
    }
  },
};
</script>

<style lang="scss" scoped>
.admin-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100%;

  .header {
    padding: 10px 10%;
    justify-content: space-between;
    display: flex;

    img {
      width: 100px;
      cursor: pointer;
    }

    .links {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-size: 22px;

      a {
        color: #2c3e50;
        text-decoration: none;

        &:visited,
        &:active {
          color: #2c3e50;
        }
      }

      & > div {
        margin: 0 20px;
        padding-bottom: 2px;
        cursor: pointer;
        // border-bottom: 2px solid $tuatara;
      }
    }
  }

  .container-body {
    background-color: $tuatara;
    flex-grow: 1;
    color: white;
    padding: 40px 60px;
  }

  @include responsive(mobile) {
    overflow-x: hidden;

    .header {
      padding: 10px 20px;

      .links {
        font-size: 14px;

        & > div {
          margin: 0 5px;
        }
      }
    }

    .container-body {
      padding: unset;
    }
  }
}
</style>
